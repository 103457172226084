.dialogWrapper {
  display: flex;
  flex-direction: column;
  background: var(--light-clr);
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 400px;
  padding: 24px 18px;
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--green-clr);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  .header {
    font-weight: 600;
    margin-bottom: 15px;
    text-align: center;
  }
  .closeBtn {
    position: absolute;
    right: 14px;
    top: 22px;
    filter: invert(100%);
  }
  .warning {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: var(--green-clr);
      line-height: 14px;
      margin-bottom: 4px;
    }
  }
  .alertSettingWrapper {
    position: relative;
    height: 250px;
    width: 100%;
    .loadingOverlay {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .settingSection {
    display: flex;
    flex-direction: column;
    .settings {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--medium-gray-clr);
      background-color: var(--medium-gray-2-clr);
      border-radius: 8px;
      padding: 8px 10px;
      margin-bottom: 10px;
      .settingsTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--black-clr);
        margin-top: 5px;
        display: flex;
        align-items: center;
      }
      .helpIcon {
        width: 22px;
        height: 22px;
        margin-left: 10px;
        font-size: 12px;
        cursor: pointer;
        line-height: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: var(--dark-gray-clr);
        border: 1px solid var(--dark-gray-clr);
      }  
      .proSettingActionBtn {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        button {
          font-size: 14px;
        }
      }
    }
    .izanaviSettingButton {
      display: flex;
      justify-content: center;  
      button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        width: 280px;
      }  
    }
  }
  .effectAllCheckbox {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    input {
      margin-right: 10px;
      cursor: pointer;
    }
    span {
      font-size: 13px;
      font-weight: 600;
      line-height: 21px;
      border-radius: 3px;
    }
  }
  .buttonGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .settingBtn {
      button {
        font-size: 14px;
      }
    }
    .linkBtn {
      margin-top: 3px;
    }
  }
}

.proNotificationSetting {
  display: flex;
  align-items: center;
  background-color: #F3F7F5;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  .platinumLogo {
    margin-right: 10px;
    flex-shrink: 0;
  }
  .proNotification {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .text {
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 6px;
    }
    .actionBtn {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        width: 300px;
      }
    }
  }
}

.deleteAllSettingsSection {
  margin-top: 10px;
  padding-right: 14px;
  margin-left: auto;
}

.settingList {
  display: flex;
  flex-direction: column;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--black-clr);
    padding-left: 12px;
  }
  .items {
    display: flex;
    flex-direction: column;
    max-height: 320px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--green-clr);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 15px;
      border-bottom: 1px solid #F3F3F3;
      .name {
        font-size: 14px;
        line-height: 21px;
      }
      .rightSide {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        .icon {
          margin-right: 10px;
        }
      }
    }
  }
  .emptyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    .emptyText {
      margin-top: 35px;
      font-weight: 600;
      font-size: 16px;
      color: #666666;
    }
  }  
}
.setting {
  display: flex;
  flex-direction: row;
  padding: 12px 10px;
  .checkboxWrapper {
    display: flex;
    flex-direction: row;
    min-width: 70px;
    margin-right: 10px;
    cursor: pointer;
    align-items: center;
    .settingCheckbox {
      margin-right: 10px;
      cursor: pointer;
    }
    span {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.settingWrapper {
  display: flex;
  flex-direction: column;
  .settingTitle {
    font-size: 10px;
    line-height: 15px;    
    color: var(--dark-gray-clr);
    margin-bottom: 5px;
  }
  .error {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 3px;
    color: red;
  }
  .lowHighSetting {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .setNumber {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    input {
      width: 80px;
      border-radius: 4px;
      padding: 6px 10px;
      font-size: 12px;
      border: 1px solid var(--light-gray-clr);
      background: var(--light-clr);
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      opacity: 1;
    }
    span {
      font-size: 11px;
      line-height: 15px;
      margin-left: auto;
      margin-top: 5px;
      color: var(--dark-gray-clr);
    }
  }
  .expireSetting {
    margin-top: 10px;
    .checkboxWrapper {
      display: flex;
      cursor: pointer;
      align-items: center;
      input {
        cursor: pointer;
      }
      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }
    .expireInput {
      input {
        border-radius: 4px;
        padding: 8px 10px;
        width: 170px;
        font-size: 12px;
        border: 1px solid var(--light-gray-clr);
        background: var(--light-clr);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dialogWrapper {
    border-radius: 0px;
    min-width: unset;
    padding: 24px 10px;
  }
  .proNotificationSetting {
    padding: 8px 4px;
    .platinumLogo {
      margin-right: 6px;
    }
    .proNotification {
      .text {
        font-size: 11px;
        line-height: 18px;
      }
      .actionBtn {
        width: 90%;
        button {
          font-size: 12px;
          width: 100%;
        }
      }
    }
  }
}
