.characterAvatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 1;
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: blinker 2s linear infinite;
  }
  .loading {
    position: absolute;
    top: 10px;
    left: 0;
  }
  .characterPRPopover {
    position: absolute;
    left: -60px;
    top: 60px;
    width: 160px;
    background-color: var(--green-clr);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px 14px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    span {
      white-space: pre-line;
      font-size: 14px;
      width: 100%;
      text-align: center;
      line-height: 21px;
      cursor: pointer;
      font-weight: 600;
    }
    button {
      width: 100%;
      padding: 8px 10px;
      color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -12px;
      width: 0;
      height: 0;
      border-top: 12px solid var(--green-clr);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      transform: rotate(180deg);
    }    
  }
}

@keyframes blinker {
  50% {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 767px) {
  .characterAvatar {
    .characterPRPopover {
      padding: 8px;
      span {
        font-size: 12px;
      }
    }
  }
}
