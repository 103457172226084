.userItem {
  padding: 5px 0;
  display: flex;
  align-items: center;
  .nameText {
    color: #000000;
    margin-left: 12px;
    font-weight: 600;
    font-size: 14px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    white-space: nowrap;
    text-align: left;

  }
}

@media screen and (max-width: 1346px) {
  .userItem {
    justify-content: center;
    .nameText {
      display: none;
    }
  }
}
