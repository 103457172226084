.dashboardSidebarWrapper {
  max-width: 260px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  flex-shrink: 0;
  margin-right: 10px;
  padding-top: 10px;
  align-items: flex-start;
}

.navItem {
  color: #000000;
  cursor: pointer;
  margin-bottom: 18px;
  .headerWrapper {
    display: flex;
    align-items: center;
    .headerText {
      font-weight: 600;
      font-size: 18px;
      margin: auto;
      border-radius: 23px;
      padding: 5px 15px;
    }
    &.active {
      .headerText {
        background: #0ABC71;
        color: #fff;  
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .dashboardSidebarWrapper {
    padding-left: 8px;
    padding-right: 8px;
  }
  .navItem {
    .headerWrapper {
      padding-left: 0px;
      .headerText {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .dashboardSidebarWrapper {
    max-width: 100%;
    overflow-x: auto;
    margin-right: 0;
    flex-direction: row;
    align-items: center;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .navItem {
    flex-shrink: 0;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
