a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

#emotion-selector-timeline,
#emotion-selector-dashboard {
  z-index: 1000;
}

img {
  max-width: 100%;
  display: block;
}

button {
  border: none;
  background: none;
}

html {
  font-size: 10px;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

:root {
  --color-border: #ececec;
  --general-font-size: 16px;
  --main-font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
    'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  --noto-font-family: 'Noto Sans JP', sans-serif;
  --main-font-weight: normal;

  --light-clr: #fff;
  --light-primary-clr: #f9f9f9;
  --dark-clr: #23272a;
  --black-clr: #000000;
  --black-primary-clr: #212624;
  --black-primary-1-clr: #333333;
  --black-secondary-clr: #4d5150;
  --gray-clr: #cccccc;
  --light-gray-clr: #eeeeee;
  --light-gray-1-clr: #FAFAFA;
  --light-gray-2-clr: #F1F1F1;
  --light-gray-3-clr: #E5E5E5;
  --light-gray-4-clr: #E8E8E8;
  --dark-gray-clr: #666666;
  --medium-gray-clr: #999999;
  --medium-gray-2-clr: #F3F3F3;
  --medium-gray-3-clr: #727272;
  --medium-gray-4-clr: #B4B4B4;
  --brand-clr: #7289da;
  --green-clr: #0abc71;
  --light-green-clr: #e4fff3;
  --yellow-clr: #FDE82B;
  --light-yellow-clr: #F4E625;
  --medium-yellow-clr: #F2E654;
  --light-red-clr: #F8195C;
  --dark-blue-clr: #3983F7;
  --light-red-1-clr: #FF2D55;
  --red-clr: #FF0000;
  --red-orange-clr: #F54D4D;
  --orange-clr: #fdb62b;
  --medium-orange-clr: #D67C1C;
  --dark-orange-clr: #FD6F1F;
  --gray-2-clr: #373B39;
  --pink-clr: #FF7CBB;
  --primary-title-font-size: 4.8rem;
  --secondary-title-font-size: 4rem;
  --num-of-grid-columns: 4;
  --section-spacing: 5.6rem 2.4rem;
  --container-width: 126rem;
}

@media screen and (min-width: 768px) {
  :root {
    --num-of-grid-columns: 8;
    --section-spacing: 8rem 4rem;
    --primary-title-font-size: 5.6rem;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --num-of-grid-columns: 12;
    --section-spacing: 12rem 4rem;
  }
}

body {
  font-size: var(--general-font-size);
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight);
  display: block;
  min-width: 320px;
}

* {
  font-family: var(--main-font-family);
}

@media screen and (max-width: 600px) {
  .yp-app {
    zoom: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .yp-app {
    padding-bottom: 66px;
  }
}

html {
  scroll-behavior: smooth;
}

.react-player__preview {
  min-height: 300px;
  position: relative;
}
