.searchInputWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  margin: auto;
  .input {
    background: url(/images/icons/search.svg) no-repeat scroll 16px 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 40px;
    box-sizing: border-box;
    width: 593px;
    height: 40px;
    outline: none;
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
      color: #666666;
    }
  }
  .clearBtn {
    position: absolute;
    top: 12px;
    right: calc(50% - 280px);
  }
  .contentWrapper {
    z-index: 10000;
    width: 593px;
    padding: 6px;
    max-height: 360px;
    overflow-y: scroll;
    position: absolute;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }  
    top: 46px;
    right: calc(50% - 300px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #fff;
    text-align: left;
    .label {
      color: #908888;
      font-size: 11px;
      padding-bottom: 4px;
      padding-left: 8px;
    }
    .menuWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        display: flex;
        color: #4F4F4F;
        border-radius: 5px;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        cursor: pointer;
        padding: 6px 10px;
        height: 36px;
        background: #fff;
        font-family: 'Hiragino Kaku Gothic Pro';
        font-style: normal;
        .labelIcon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          padding-top: 2px;
        }
        .delete {
          width: 24px;
          height: 24px;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .name {
          width: calc(100% - 12px);
          font-size: 13px;
          line-height: 19px;
          padding-right: 10px;
          color: #000;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;      
        }
        &:hover {
          background: #F2F2F2;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .searchInputWrapper {
    width: 100%;
    form {
      top: 48px;
      width: calc(100% - 30px);
      .input {
        width: 100%;
      }
    }
    .contentWrapper {
      right: 10px;
      width: calc(100% - 30px);
      .menuWrapper li .name {
        font-size: 12px;
      }
    }
    .clearBtn {
      right: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .searchInputWrapper {
    width: 100%;
    form {
      width: calc(100% - 10px);
      .input {
        width: 100%;
      }
    }
    .contentWrapper {
      right: 15px;
      width: calc(100% - 30px);
      .menuWrapper li .name {
        font-size: 11px;
      }
    }
    .clearBtn {
      right: 25px;
    }
  }
}
