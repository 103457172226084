.dialogWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-size: 18px;
  padding: 24px 39px;
  .header {
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
    a {
      color: var(--green-clr);
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: hidden;
    .button {
      cursor: pointer;
      border: none;
      background: transparent;
      width: 185px;
      font-size: 12px;
      display: flex;
      justify-content: center;
    }
  }
  .textBox {
    min-width: 500px;
  }
}

@media screen and (max-width: 767px) {
  .dialogWrapper {
    .header {
      margin-bottom: 10px;
    }
    font-size: 14px;
    min-width: 296px;
    padding: 8px;

    .textBox {
      min-width: 250px;
      * {
        font-size: 12px !important;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .dialogWrapper {
    min-width: unset;
    font-size: 12px;
    .textBox {
      * {
        font-size: 11px !important;
      }
    }
  }
}
