.linkBtn {
  font-size: 12px;
  line-height: 150%;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  &.nonUnderline {
    text-decoration: none;
  }
  &.normal {
    color: #000;
  }
  &.green {
    color: var(--green-clr);
  }
  &.red {
    color: red;
  }
}
