.profileViewUsers {
  display: flex;
  flex-direction: column;
  width: calc(100% - 25px);
  .usersList {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-top: 2px;
    .user {
      padding: 2px 3px;
      margin-bottom: 1px;
      :global(.userNameText) {
        font-size: 10px;
        line-height: 14px;
      }
      :global(.userJobText) {
        font-size: 8px;
        line-height: 14px;
      }
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 18%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }
  }
  .seemore {
    padding: 3px 8px;
    border: 1px solid #ccc;
    font-size: 10px;
    line-height: 14px;
  }
}

.customAvatar {
  width: 52px;
  height: 52px;
  position: relative;
  .avatar1 {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
  .avatar2 {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    left: 0;
    position: absolute;
    z-index: 1;
    bottom: 0;
  }
  .emotion {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    bottom: 9px;
    right: 6px;
  }
}
