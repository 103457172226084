.timelineNavWrapper {
  width: 100%;
  .navItemWrapper {
    display: block;
    width: 100%;
  }
  min-width: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 15px;
}

.navItem {
  color: #000000;
  margin-bottom: 12px;
  flex-shrink: 0;
  width: 272px;
  cursor: pointer;
  margin-bottom: 5px;
  .headerWrapper {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    .headerText {
      font-weight: 500;
      font-size: 16px;
      margin: 0;
      margin-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
    .expandIcon {
      margin-left: auto;
    }
    &:hover {
      opacity: 0.8;
      background: #ECECEC;;
    }
    &.active {
      color: #000;
      background: #E0E0E0;
      .headerText {
        font-weight: 600;
      }
    }
  }
  .children {
    padding-left: 30px;
    padding-top: 6px;
    .emptyText {
      font-size: 12px;
      color: #666666;
    }
    ul {
      font-size: 14px;
      li {
        cursor: pointer;
        padding-top: 4px;
        padding-bottom: 4px;
        &.active {
          font-weight: 600;
          display: flex;
          align-items: center;
        }
      }
    }
    .seeMore {
      cursor: pointer;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: 600;
      font-size: 12px;
      color: #04b56a;
      width: fit-content;
    }
  }
}

@media screen and (max-width: 1346px) {
  .timelineNavWrapper {
    width: 75px;
  }
  .navItem {
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .headerWrapper {
      justify-content: center;
      flex-direction: column;
      padding: 8px 2px;
      .headerText {
        font-size: 10px;
        line-height: 15px;
        margin-top: 5px;
        text-align: center;
        margin-left: 0;
      }
      .expandIcon {
        margin-left: 0;
      }
    }
    .children {
      padding-left: 0;
      .emptyText {
        display: none;
      }
    }
  }
}
