.mobileBottomBar {
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  z-index: 1102;
  background-color: #fff;
  border-top: 1px solid #ccc;
  height: 64px;
  .item {
    width: 21%;
    display: flex;
    cursor: pointer;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 6px 4px 8px 4px;
    &.middle {
      width: 16%;
    }
    &.active {
      border-bottom: 3px solid var(--green-clr);
    }
    justify-content: space-between;
    .icon {
      display: flex;
      align-items: flex-end;
    }
    .title {
      font-size: 9px;
      line-height: 9px;
      font-weight: 600;
    }
    .corner {
      border-top-left-radius: 3px;
      position: absolute;
      left: 0;
      top: 0;
      animation: blinker 3s linear infinite;
    }
  }
  .roundIcon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--green-clr);
    box-shadow: 0 6px 6px #888888;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

@media screen and (max-width: 375px) {
  .mobileBottomBar {
    .item .title {
      font-size: 9px;
    }
  }
}

@media screen and (max-width: 325px) {
  .mobileBottomBar {
    .item .title {
      font-size: 8px;
    }
  }
}
