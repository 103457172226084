.notificationContent {
  width: 100%;
  min-width: 0;
  color: #666666;
  font-size: 13px;
  display: flex;
  .leftSide {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
  }
  .mediaThumb {
    width: 72px;
    height: 72px;
    overflow: hidden;
    outline: none;
    border-radius: 4px;
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 33px #0000001a;
    position: relative;
    text-align: center;
  }
  .mediaAudio {
    width: 72px;
    height: 72px;
    margin-left: auto;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border-radius: 4px;
    position: relative;
    background: #0abc71;
    text-align: center;
  }

  .headerGroup {
    display: flex;
    align-items: flex-start;
  }
  .text {
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-bottom: 1px;
    font-size: 11px;
  }
  .header {
    font-size: 12px;
    padding-right: 5px;
    color: #000;
    margin-bottom: 5px;
    strong {
      font-weight: 600;
    }
  }
  .timeSinceWrapper {
    width: fit-content;
    flex-shrink: 0;
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-top: 5px;
    .nonReadFlag {
      height: 10px;
      width: 10px;
      background: #0abc71;
      border-radius: 50%;
      margin-right: 6px;
      margin-top: 1px;
    }
    .timeSince {
      font-size: 10px;
    }
  }
}

.svgIcon {
  width: 56px;
  height: 56px;
  &.round img {
    border-radius: 50%;
  }
}

