.container {
  max-width: 1412px;
  margin: auto;
}

.dashboardPageWrapper {
  display: grid;
  grid-template-columns: [sidebar-start] 270px [sidebar-end content-start] auto [content-end right-column-start] 60px [right-column-end];
  grid-template-rows: auto 1fr;
  .sidebar {
    grid-column: sidebar-start / sidebar-end;
    border-right: 1px solid #eeeeee;
  }
  .content {
    grid-column: content-start / content-end;
    border-right: 1px solid #eeeeee;
  }
}

@media screen and (max-width: 1100px) {
  .dashboardPageWrapper {
    grid-template-columns: [sidebar-start] auto [sidebar-end content-start] 1fr [content-end];
  }
}

@media screen and (max-width: 1000px) {
  .dashboardPageWrapper {
    display: flex;
    flex-direction: column;
  }
}
