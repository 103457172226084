.iconWrapper {
  flex-shrink: 0;
  display: block;
  position: relative;
  font-size: 0;
  border: 1px solid #eee;
  &.ring {
    border: 3px solid #ebebeb;
    padding: 1.2px;
  }
  &.online {
    border-color: #4ae5c9;
  }
  &.tip-1 {
    border-color: #09C1D4;
  }
  &.tip-2 {
    border-color: #B381FF;
  }
  &.tip-3 {
    border-color: #F9B48D;
  }
  &.tip-4 {
    border-color: #c3c3c3;
  }
  &.tip-5 {
    border-color: #ddba04;
  }
  &.redRing {
    border-color: var(--light-red-1-clr);
  }
  border-radius: 100%;
}

.roundWrapper {
  overflow: hidden;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
  }
}

.badge {
  position: absolute;
  bottom: -6px;
  right: -6px;
}

.lightDot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  background: var(--green-clr);
  bottom: -2px;
  right: 2px;
  border: 2px solid #fff;
}

.liveFlag {
  position: absolute;
  font-size: 8px;
  line-height: 12px;
  background: var(--light-red-1-clr);
  color: #fff;
  font-weight: 600;
  padding: 3px 4px;
  width: 40px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  &.md {
    font-size: 10px;
    padding: 4px 6px;
    width: 48px;
  }
  &.lg {
    font-size: 12px;
    padding: 6px 8px;
    width: 60px;
  }
}

@media screen and (max-width: 440px) {
  .liveFlag {
    font-size: 7px;
    &.md {
      font-size: 9px;
    }
    &.lg {
      font-size: 11px;
    }  
  }
}
