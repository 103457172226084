.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    margin-bottom: 10px;
  }
  .text {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #000;
  }
}
