.mainTemplateWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.notRightSidebar {
    .pageContent {
      .mainSection {
        width: 1120px;
        border-right: none;
        .content {
          max-width: 100%;
        }
      }
    }
  }
}

.pageContent {
  display: flex;
  max-width: 1412px;
  margin: 0 auto;
}

.leftColumn {
  padding: 10px 10px 0 10px;
  position: sticky;
  top: 64px;
  flex-shrink: 0;
  height: calc(100vh - 65px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainSection {
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  width: 722px;
  flex-shrink: 0;
  .content {
    max-width: 722px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.rightColumn {
  padding-right: 24px;
  padding-left: 24px;
  position: sticky;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-top: 10px;
  top: 64px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  width: 398px;
  max-width: 398px;
}

@media screen and (max-width: 1412px) {
  .mainTemplateWrapper {
    &.notRightSidebar {
      .pageContent {
        .mainSection {
          width: calc(100vw - 292px);
          border-right: none;
        }
      }
    }  
  }
  .rightColumn {
    width: calc(100vw - 722px - 292px);
  }
}

@media screen and (max-width: 1346px) {
  .rightColumn {
    width: calc(100vw - 95px - 722px);
  }
}

@media screen and (max-width: 1346px) {
  .mainTemplateWrapper {
    &.notRightSidebar {
      .pageContent {
        .mainSection {
          width: calc(100vw - 95px);
        }
      }
    }
  }
  .pageContent {
    margin: 0;
  }
}

@media screen and (max-width: 1100px) {
  .rightColumn {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .mainSection {
    flex-shrink: 1;
  }  
}

@media screen and (max-width: 767px) {
  .leftColumn {
    display: none;
  }
  .mainSection {
    width: 100%;
    flex-shrink: 0;
    max-width: unset;
    .content {
      max-width: 100%;
    }
    .pageContent {
      width: 100%;
    }
  }
  .mainTemplateWrapper {
    &.notRightSidebar {
      .pageContent {
        .mainSection {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
