.splashScreenWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  z-index: 2000;
  overflow: hidden;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// to account for zoom 0.7 of body
@media screen and (max-width: 600px) {
  .splashScreenWrapper {
    height: calc(100vh * 10 / 7);
    max-height: -webkit-fill-available;
  }
}
