.header {
  top: 0;
  z-index: 1100;
  background-color: #ffffff;
  position: sticky;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eee;
  .container {
    width: 100%;
    .firstLine {
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      padding: 12px;

      .logo {
        flex-shrink: 0;
        margin-right: 5px;
        cursor: pointer;
        font-size: 0;
        img {
          border-radius: 8px;
          cursor: pointer;
        }
      }
      .coinWrapper {
        display: flex;
        padding: 4px 6px;
        align-items: center;
        justify-content: space-between;
        background: #F3F2E9;
        max-width: 220px;
        flex-shrink: 0;
        border-radius: 6px;
        margin-right: 15px;
        margin-left: 4px;
        cursor: pointer;
        .left {
          display: flex;
          align-items: center;
          margin-right: 15px;
          span {
            font-family: 'Hiragino Kaku Gothic Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            padding-top: 5px;
            text-align: center;
            line-height: 19px;
          }  
        }
        .right {
          border-radius: 4px;
          cursor: pointer;
          padding: 4px 8px;
          margin-left: auto;
          position: relative;
          display: flex;
          background: radial-gradient(55.63% 55.63% at 55.31% 55.63%, #D19CF8 0%, #581B86 47.83%, #1F013B 100%);
          align-items: center;
          .blurOverlay {
            filter: blur(4px);
            background: #FFD883;
            position: absolute;
            width: 80px;
            opacity: 0.4;
            height: 20px;
            top: 5px;
            left: 5px;        
          }
          .text {
            font-weight: 600;
            color: #FFE893;
            font-size: 11px;
            line-height: 15px;
            margin-right: 5px;
          }
          .number {
            background: radial-gradient(167.15% 137.38% at 6.95% 0%, #E6A830 0%, #FFD480 25.63%, #FFE68F 41.95%, #F2A01B 62.64%, #C07D17 86.48%);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            color: #5B1B1B;
            position: relative;
            border: 2px solid #E6A830;
            .hasReadyMission {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              top: -2px;
              right: -2px;
              flex-shrink: 0;
              position: absolute;
              background: red;
            }
            .inprogressNumber {
              font-size: 10px;
              font-weight: 700;
              line-height: 14px;
              position: absolute;
              white-space: nowrap;
              left: 50%;
              top: 50%;
              width: 30px;
              height: 24px;
              transform: translate(-50%, -50%);
              display: flex;
              align-items: center;
              justify-content: center;  
            }
            .missionsDialogPopover {
              display: none;
              position: absolute;
              z-index: 1;
              right: -50px;
              top: 45px;
              width: 160px;
              background-color: var(--green-clr);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              border-radius: 8px;
              padding: 8px 14px;
              color: #fff;
              cursor: pointer;
              text-align: center;
              span {
                white-space: pre-line;
                font-size: 14px;
                width: 100%;
                text-align: center;
                line-height: 21px;
                cursor: pointer;
                font-weight: 600;
              }
              button {
                width: 100%;
                padding: 8px 10px;
                color: #fff;
                border: 1px solid #fff;
                cursor: pointer;
                border-radius: 4px;
                margin-top: 10px;
              }
              &:before {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -12px;
                width: 0;
                height: 0;
                border-top: 12px solid var(--green-clr);
                border-right: 12px solid transparent;
                border-left: 12px solid transparent;
                transform: rotate(180deg);
              }    
            }
          }
        }
      }
      .missionBtn {
        border: 2px solid #000000;
        border-radius: 4px;
        cursor: pointer;
        padding: 3px 4px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        margin-right: 15px;
      }
      .loginBtn {
        flex-shrink: 0;
        &:last-child {
          margin-left: 12px;
        }
        button {
          font-size: 12px;
          outline: none;
        }
      }
      .badgeWrapper {
        margin-left: 11px;
        margin-right: 5px;
        width: 32px;
        flex-shrink: 0;
      }
      .membershipIcon {
        margin-left: 4px;
        margin-right: 3px;
        width: 60px;
        flex-shrink: 0;
      }
      .avatarWrapper {
        margin-right: 15px;
      }
      .dropDownWrapper {
        width: 40px;
        flex-shrink: 0;
        z-index: 10;
        position: relative;
      }
      .notificationWrapper {
        flex-shrink: 0;
        z-index: 10;
        position: relative;
      }
      .popperWrapperNotification {
        display: none;
        position: absolute;
        right: 20px;
        top: 45px;
        width: 380px;
        max-height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #fff;
      }
      .popperWrapper {
        display: none;
        position: absolute;
        right: 0;
        top: 45px;
        max-width: 320px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background: #fff;
        text-align: center;
      }
      .arrowWrapper {
        cursor: pointer;
        &.flip {
          transform: rotate(180deg);
        }
      }
    }
    .inviteIcon {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 7px;
      div {
        border-radius: 50%;
      }
      border: 1px solid #0abc71;
      background: #0abc71;
      width: 40px;
      height: 40px;
    }
    .linkWrapper {
      cursor: pointer;
      position: relative;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      flex-shrink: 0;
      &:hover {
        background: #eee;
      }
      &.active {
        background-color: #0abc71;
        border: 1px solid #0abc71;
      }
      .hasNewNotification {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        top: 0;
        right: 0;
        flex-shrink: 0;
        position: absolute;
        background: red;
      }
      img {
        border-radius: 8px;
      }
    }
  }
}

.secondLine {
  border: 1px solid #eee;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 7px 12px;
  padding-right: 52px;
  min-height: 42px;
  background: #F3F3F3;
  &::-webkit-scrollbar {
     display: none;
  }
  .globalPostTopics {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    left: 0;
  }
  .topicContainer {
    display: flex;
    overflow-x: scroll;
    // firefox hide scroll
    scrollbar-width: none;
    &::-webkit-scrollbar {
       display: none;
    }
    .topicBtn {
      cursor: pointer;
      margin: 0px 4px 0px 4px;
      padding: 4px 8px;
      color: #ffffff;
      background-color: #3983f7;
      border-radius: 23px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      white-space: nowrap;
    }
  }
  .showMore {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 42px;
    right: 0;
    cursor: pointer;
    background: #F3F3F3;
  }
  .backWard {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    cursor: pointer;
    img {
      transform: rotate(-180deg);
    }
    background: #F3F3F3;
  }
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  min-width: 300px;
  z-index: 1000;
  .userInfo {
    width: 100%;
    padding: 14px 12px;
    border-bottom: 1px solid #eeeeee;
  }
  .updateProfile {
    font-size: 14px;
    color: #0abc71;
    display: block;
    text-align: left;
    margin-left: 70px;
  }
  .btn {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
  }
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 16px;
    &:hover {
      background: rgba(0, 0, 0, 0.01);
    }
    span {
      margin-left: 10px;
    }
  }
}

.schedulePostAlert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .header {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .menuWrapper {
    width: 100%;
    min-width: unset;
  }
  .header {
    margin-bottom: 0px;
    .container {
      .firstLine {
        .popperWrapper {
          width: 320px;
        }
        .coinWrapper {
          margin-right: 8px;
          .left {
            margin-right: 5px;
          }
          .right {
            .number .inprogressNumber {
              font-size: 9px;
            }
            .number .missionsDialogPopover {
              right: -70px;
              width: fit-content;
              min-width: 180px;
              padding: 8px;
              span {
                font-size: 12px;
                line-break: 18px;
              }
            }
          }
        }
        .avatarWrapper {
          margin-right: 8px;
        }
        .missionBtn {
          margin-right: 3px;
        }
        .loginBtn {
          flex-shrink: 0;
          margin-left: 8px;
          button {
            font-size: 12px;
          }
        }  
      }
    }
  }
}
