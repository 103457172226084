.loader {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.userInfoLoader {
  max-width: 272px;
}

.trendingTopicKwLoader {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  .topic {
    padding: 6px;
    border-bottom: 1px solid #eee;
  }
}

// @media screen and (max-width: 767px) {
//   .loader {
//     display: none;
//   }
// }

@media screen and (max-width: 1346px) {
  .userInfoLoader {
    display: none;
  }
}
