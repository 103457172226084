.labelButton {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  border-radius: 18px;
  padding: 4px 6px;
  color: #fff;
  cursor: pointer;
  width: fit-content;
  font-weight: 900;
  &.pink {
    background: linear-gradient(102.49deg, #FF67CB 16.83%, #EB31AB 86.48%);
    &:hover {
      background: linear-gradient(102.49deg, #FF67CB 4.83%, #EB31AB 60.48%);
    }
  }
  &.tip-1 {
    background: linear-gradient(111.64deg, #78EFFF 7.18%, #3EE0F3 19.82%, #2DD9F0 46.77%, #09C1D4 102.99%);
  }
  &.tip-2 {
    background: linear-gradient(109.04deg, #CB9AFF 14.47%, #B381FF 28.51%, #AA59F3 52.16%, #9C27F2 78.88%);
  }
  &.tip-3 {
    background: linear-gradient(109.86deg, #BE764E 0.05%, #C5793B 23.87%, #F9B48D 40.67%, #A0561F 60.38%, #713B09 78.9%, #793D1B 91.59%);
  }
  &.tip-4 {
    background: linear-gradient(50.9deg, #ccc 79.69%, #fafafa 39.57%, #c3c3c3 56.52%, #848484 97.36%, #686868 76.78%, #afafaf 91.67%);
  }
  &.tip-5 {
    background: linear-gradient(18.25deg, #f8e488 -74.92%, #f6cc35 9.24%, #fff8d4 8.9%, #ffd80e 0.69%, #ddba04 68.04%, #af820f 86.66%);
  }
  &.gray {
    background: #eee;
    color: #686161;
  }
  &.white {
    background: #fff;
    color: #000;
    border: 1px solid #ccc;
    padding: 6px 8px;
  }
  &.green {
    background: var(--green-clr);
    color: #fff;
    border: 1px solid #ccc;
    padding: 6px 8px;
  }
  &.green-1 {
    background: linear-gradient(102.49deg, #0abc71 16.83%, #029d5c 86.48%);
    &:hover {
      background: linear-gradient(102.49deg, #0abc71 4.83%, #019b5b 60.48%);
    }
    color: #fff;
    padding: 6px 8px;
  }
}
