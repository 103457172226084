.title {
  display: flex;
  align-items: center;
  .titleLeftSide {
    display: flex;
    flex-direction: column;
    .text {
      font-size: 24px;
    }
  }
  .readAll {
    margin-left: auto;
    font-size: 12px;
    color: var(--green-clr);
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  .dropDownWrapper {
    margin-left: auto;
    cursor: pointer;
    flex-shrink: 0;
    z-index: 10;
    position: relative;
    .popperWrapper {
      display: none;
      position: absolute;
      right: 10px;
      padding-top: 8px;
      padding-bottom: 8px;
      top: 35px;
      width: 240px;
      &.bigSize {
        width: 280px;
      }
      flex-direction: column;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding-left: 8px;
      padding-right: 8px;
      background: #fff;
    }
    .arrowWrapper {
      cursor: pointer;
    }
  }
  .option {
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    padding: 8px 10px;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }
    .text {
      font-size: 14px;
      line-height: 24px;
      color: #666666;
    }
    &.subOption {
      padding-left: 30px;
    }
  }
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.groupBtn {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  .filterBtn {
    padding: 6px 8px;
    font-size: 13px;
    line-height: 18px;
    margin-right: 10px;
    &.active, &:hover {
      background: var(--green-clr);
      color: #fff;
    }
  }
}

.section {
  .item {
    padding: 6px 10px;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    .user {
      display: flex;
    }
    .post {
      width: 100%;
      padding-left: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &:not(.isProfileViewItem):hover {
      background: #eeeeeec5;
    }
    &.isReadItem {
      background: #eeeeeec5;
    }
  }
  .loadMoreWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 150px;
  }
}

.empty {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 805px) {
  .post {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .title {
    margin-bottom: 24px;
    padding-left: 10px;
    .dropDownWrapper .popperWrapper {
      &.bigSize {
        width: 300px;
      }
    }
    .dropDownWrapper .option .text {
      font-size: 13px;
    }
  }
  .section {
    .item {
      padding: 10px 20px;
      .post .textContent {
        font-size: 14px;
      }
    }
  }
}
