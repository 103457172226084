.button {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background: #0abc71;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.allowBreakLine {
    white-space: pre-line;
  }
  &.loading {
    div {
      max-height: 24px;
    }
  }
  &.rounded {
    border-radius: 23px;
  }
  &.disabled {
    opacity: 0.33;
    cursor: not-allowed;
  }
  &:hover:not(.disabled) {
    opacity: 0.85;
  }
  &.alternateColor {
    background-color: #fdb62b;
  }
  &.alternateColorFaded {
    background-color: #cccccc;
  }
  &.alternateColorWarning {
    background-color: #fa553f;
  }
  &.red {
    background-color: #F8264C;
  }
  &.black {
    background-color: #211B1C;
  }
  &.lightBlue {
    background-color: #24D0C8;
  }
  &.lightBlueHover {
    background-color: #fff;
    border: 1px solid #24D0C8;
    color: #24D0C8;
    &:hover {
      background-color: #24D0C8;
      color: #fff;
    }
  }
  &.grayGreen {
    background-color: #B9D0C1;
  }
  &.gray {
    background-color: var(--medium-gray-clr);
  }
  &.lightGray {
    background-color: var(--light-gray-1-clr);
    border: 1px solid var(--medium-gray-clr);
    color: var(--black-clr);
  }
  &.whiteGreen {
    color: #fff;
    border: 1px solid #fff;
    background-color: var(--green-clr);
  }
  &.greenWhite {
    color: var(--green-clr);
    border: 1px solid var(--green-clr);
    background-color: #fff;
  }
  &.whiteGray {
    color: #666;
    border: 1px solid #666;
    background-color: #fff;
  }
  &.linearOrange {
    background:rgba(255,255,255,0);
    background: -webkit-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
    background: -o-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
    background: -moz-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
    background: -ms-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
    background: linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
  }
  &.linearOrangeHover {
    color: #564B3C;
    border: 1px solid #ccc;
    background-color: transparent;
    &:hover {
      color: #fff;
      background:rgba(255,255,255,0);
      background: -webkit-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
      background: -o-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
      background: -moz-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
      background: -ms-linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);
      background: linear-gradient(-180deg , rgba(255,59.8,33.18,1) 0%, rgba(241.35,104.71,20.88,1) 39%, rgba(243,193,17,1) 100%);    
    }
  }
  &.hasIcon {
    display: flex;
    align-items: center;
    position: relative;
    // TODO: custom style for icon when need
    .icon {
      position: relative;
    }
  }
  &.blackAndWhite {
    color: #000000;
    border: 1.5px solid #000000;
    background-color: transparent;
    &.loading {
      background-color: #000000;
    }
    &:hover:not(.disabled) {
      opacity: 1;
      color: #ffffff;
      background-color: #000000;
    }
  }
  &.faded {
    background-color: #cccccc;
  }
  &.fullWidth {
    width: 100%;
  }
  &.fadingStyle {
    color: #666666;
    border: 1px solid #cccccc;
  }
  &.bigGuyStyle {
    border-radius: 12px;
    align-items: center;
    height: 50px;
  }
}
