.membershipPRTextSection {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--light-gray-clr);
  font-family: var(--noto-font-family);
  padding: 16px 24px;
  margin-bottom: 20px;
  .title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .description {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .actionBtn {
    display: flex;
    justify-content: flex-end;
    button {
      font-size: 14px;
    }
  }
}
