.userInfoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
    min-width: 0;
    a {
      max-width: 100%;
    }
    .userNameText {
      max-width: 100%;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      white-space: nowrap;
      text-align: left;
    }
    .userJobText {
      max-width: 100%;
      font-size: 11px;
      color: #666666;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }
  .badge {
    flex-shrink: 0;
    margin-left: 12px;
    margin-right: 1px;
  }
  .membershipIcon {
    flex-shrink: 0;
    margin-left: 10px;
    margin-right: 1px;
  }
  .follow {
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .userInfoWrapper .userInfo {
    .userNameText {
      font-size: 14px;
      max-width: 175px;
    }
    .userJobText {
      font-size: 10px;
      max-width: 175px;
    }
  }
  .userInfoWrapper .badge {
    margin-left: 8px;
  }
}

.deletedUser {
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    font-family: Hiragino Kaku Gothic Pro;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
  }
}  
