.copyrightWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 10px;
  color: #666666;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 10px;
  .copyright {
    color: #cccccc;
  }
}

@media screen and (max-width: 1346px) {
  .copyrightWrapper {
    display: none;
  }
}
