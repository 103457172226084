.snackbar {
  :global(.MuiAlert-message) {
    font-size: 16px;
  }
  :global(.MuiAlert-icon) {
    font-size: 26px;
  }
  :global(.MuiSvgIcon-fontSizeSmall) {
    font-size: 20px;
  }
}
