.container {
  max-width: 1412px;
  margin: auto;
}

.uploadingProgress {
  position: fixed;
  bottom: 50px;
  left: 30px;
  z-index: 1299;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .isLp {
    padding-bottom: 0 !important;
  }
}
