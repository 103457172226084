.preloader {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sideBarHotKeywordsList {
  * {
    font-family: var(--noto-font-family);  
  }
  // title
  .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  // keywords list
  .keywords {
    width: 100%;
    padding: 20px 12px;
    border: 1px solid var(--light-gray-clr);
    border-radius: 12px;
    // transition: height 0.5s;
    // item with topicName and number of posts space between
    .keywordItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:not(.preloading) {
        &:hover {
          background-color: #e5e5e5;
        }
      }
      &.preloading {
        cursor: none;
      }
      // topicName
      .topicName {
        font-size: 15px;
        line-height: 22px;
        font-weight: 600;
        color: var(--black-clr);
      }
      // number of posts
      .postsCount {
        font-size: 15px;
        line-height: 22px;
        flex-shrink: 0;
        color: var(--black-clr);
      }
    }
  }
  .readMoreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    button {
      width: fit-content;
      color: var(--green-clr);
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-decoration: none;
    }
  }
}
