.preloader {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sideBarRecommendCreatorsList {
  * {
    font-family: var(--noto-font-family);  
  }
  margin-top: 20px;
  // title
  .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  // creators list
  .creatorsList {
    width: 100%;
    padding: 20px 12px;
    border: 1px solid var(--light-gray-clr);
    border-radius: 12px;
    // transition: height 0.5s;
    // item with topicName and follow Btn
    .creatorItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      min-height: 41px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:not(.preloading) {
        &:hover {
          background-color: #e5e5e5;
        }
      }
      &.preloading {
        cursor: none;
      }
      .followBtn {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        flex-shrink: 0;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 36px;
        border: 1px solid var(--green-clr);
        padding: 4px 8px;
        color: var(--green-clr);
        background-color: var(--light-clr);
        &:hover {
          background-color: var(--green-clr);
          color: var(--light-clr);  
        }
      }
    }
  }
  .readMoreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    button {
      width: fit-content;
      color: var(--green-clr);
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-decoration: none;
    }
  }
}

.creatorInfoWrapper {
  display: flex;
  width: calc(100% - 85px);
  .creatorAvatar {
    margin-right: 10px;
  }
  .creatorInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 60px);
    .userNameWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      .userName {
        max-width: 100%;
        width: 100%;
        min-width: 50px;
        color: var(--black-clr);
        font-weight: 600;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .userJob {
      max-width: 100%;
      width: 100%;
      min-width: 50px;
      margin-top: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      overflow: hidden;
      font-size: 14px;
      color: var(--black-primary-1-clr);
      line-height: 21px;
    }
  }
}
