.infiniteScrollWrapper {
  position: relative;
  width: 100%;
}

.loader {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 10%;
  z-index: -1;
}

.loader2 {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  z-index: -1;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
}
